<template>
<!--  {{ category }}-->
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "NewFrame",
  inheritAttrs: false,
  props: [
    'layout',
    'category',
    'isDigitalSignage',
  ],
  computed: {
    ...mapGetters({
      restURI: 'config/restURI'
    })
  },

  async created() {
    window.history.back()
    // if (templateAttr?.value?.toLowerCase() !== 'splitview') {
    // window.location = window.history.state.back
  },
   mounted() {
     setTimeout(() => {
       window.open(this.category.frameUrl, "support", "_blank")
     }, 500);

  }
}

</script>
